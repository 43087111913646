import React, { ReactElement } from "react";
import { WindowLocation, Location } from "@reach/router";

import SEO from "../components/Seo";

import Layout from "../components/CalculatorLandingpageSection";

import { UrlParams } from "../types";
import { parseQueryParams } from "../utils";

function getPromoCode(location: WindowLocation): string {
  const params: UrlParams = parseQueryParams(location.search);
  const { promoCode } = params;
  return promoCode;
}
const Index = (): ReactElement => {
  return (
    <div>
      <SEO title="stromee Rechner für landingpages" />
      <Location>
        {({ location }) => (
          <Layout
            promoCode={getPromoCode(location)}
            /*branding={branding}*/
          />
        )}
      </Location>
    </div>
  );
};

export default Index;
