import React, { ReactElement, useState, useEffect } from "react";
import { navigate } from "@reach/router";
import cn from "classnames";

import styles from "./styles.mod.scss";

import Preloader from "../SVG/Preloader";
import { PreloaderContainer } from "../Contract/Layout/styled";

import {
  EnergyTariffValues,
  Tariff,
  EnergyCalculatorFormValues,
} from "../../types";

import { createRequestState } from "../../utils";

import { EnergyCalculator } from "../EnergyCalculator";

interface CalculatorLandingpageProps {
  promoCode?: string;
  branding?: number;
}

const CalculatorLandingpage = ({
  promoCode,
  branding,
}: CalculatorLandingpageProps): ReactElement => {
  const [tariff, setTariff] = useState(
    createRequestState<EnergyTariffValues>()
  );

  const [isPending, setIsPending] = useState(true);
  useEffect(() => {
    setIsPending(false);
  }, []);

  const formValues: EnergyCalculatorFormValues = {};
  if (promoCode) {
    formValues.promoCode = promoCode;
  }

  useEffect(() => {
    if (tariff.isPending === false && tariff.error === null && tariff.data) {
      const data: EnergyTariffValues = tariff.data;
      const resultTariff: Tariff = data.tariff;
      const resultFormValues: EnergyCalculatorFormValues = data.formValues;

      const paramsArray: string[][] = [];
      for (const [key, value] of Object.entries(resultTariff)) {
        paramsArray.push([key.toString(), value ? value.toString() : ""]);
      }
      for (const [key, value] of Object.entries(resultFormValues)) {
        paramsArray.push([key.toString(), value ? value.toString() : ""]);
      }
      const params: URLSearchParams = new URLSearchParams(paramsArray);

      // check if iframe
      if (window.self != window.parent) {
        window.parent.location.href = `${
          window.location.origin
        }/calculator/?${params.toString()}`;
        window.parent.location.reload();
      } else {
        navigate(`/calculator/?${params.toString()}`);
      }
    }
  });

  return isPending ? (
    <PreloaderContainer>
      <Preloader />
    </PreloaderContainer>
  ) : (
    <section className={cn(styles.calculatorSection, "container")}>
      <EnergyCalculator
        forcePromoCode={formValues?.promoCode ? true : false}
        defaultFormValues={formValues}
        setFormValues={setTariff}
      />
    </section>
  );
};

export default CalculatorLandingpage;
